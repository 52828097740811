











import { Component } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../_abstract'

import { departmentsContentFactory } from '../Departments.factory'
import {
  DepartmentsModule,
  DepartmentsModuleContent
} from '../Departments.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */

@Component({ name: 'DepartmentsModuleForm' })
export class DepartmentsModuleForm extends AbstractModuleForm<DepartmentsModule> {
  //
  protected initialContent: DepartmentsModuleContent = departmentsContentFactory()

  public icons = DashmixIconName
}
export default DepartmentsModuleForm
